import { Application } from "@hotwired/stimulus";
import ClipeboardController from "./clipboard_controller";
import ViewSelectController from "./view_select_controller";

const application = Application.start();

// Configure Stimulus development experience
application.debug = true;
window.Stimulus = application;

application.register("clipboard", ClipeboardController);
application.register("view-select", ViewSelectController);

const contextComponents = require.context(
  "../../components",
  true,
  /controller\.js$/,
);
contextComponents.keys().forEach((path) => {
  const mod = contextComponents(path);

  // Convert path into a controller identifier:
  //   example/controller.js -> example
  //   nav/user_info/controller.js -> nav-user-info
  //   foundation/header/controller.js -> header
  const identifier = path
    .match(/\.\/(.+)\/controller\.js$/)[1]
    .replaceAll("/", "-")
    .replaceAll("_", "-");

  application.register(identifier, mod.default);
});
