import { Controller } from "@hotwired/stimulus";

/**
 * @class CopyToClipboardButtonController
 */
export default class extends Controller {
  static classes = ["success"];

  static values = {
    delay: {
      type: Number,
      default: 3000,
    }
  };

  /**
   * Temporarily toggles the state of the #element.
   *
   * @returns {void}
   */
  onCopy() {
    const width = this.element.getBoundingClientRect().width;

    this.element.style.width = `${width}px`;
    this.element.classList.add(...this.successClasses);

    setTimeout(() => {
      this.element.classList.remove(...this.successClasses);
      this.element.style.width = "";
    }, this.delayValue);
  }
};
