import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["view"];
    static classes = ["hidden"];

    select({ params: { viewId } }) {
        this.viewTargets.forEach(view => {
            if (view.id === viewId) {
                view.classList.remove(...this.hiddenClasses);
            } else {
                view.classList.add(...this.hiddenClasses);
            }
        })
    }
};
