// jQuery, as we need to deal with javascript import hoisting
// good explanation: https://gorails.com/episodes/how-to-use-jquery-with-esbuild
import "./src/jquery";
import "./src/bootstrap";
import "./tenants.js";
import "@builder-design-system/view-components";
import "./controllers/index";

import Rails from "@rails/ujs";
Rails.start();
