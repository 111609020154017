"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
/**
 * @class ClipboardController
 * @classdesc A Stimulus controller for copying text to the clipboard. When triggered, it copies text from a specified target element and displays a success message.
 */
class ClipboardController extends stimulus_1.Controller {
    /**
     * Copies the text content from a specified source to the clipboard.
     * Temporarily changes the button text to indicate success.
     * Expects data-clipboard-value-param to be set to the value to be copied.
     * @async
     * @param {ClipboardEvent} event - The event object, containing any parameters defined in the `data-action` attribute.
     * @returns {Promise<void>}
     */
    copy(event) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const copyContent = event.params.value;
                yield navigator.clipboard.writeText(copyContent);
            }
            catch (err) {
                console.error("Failed to copy text:", err);
            }
        });
    }
}
exports.default = ClipboardController;
